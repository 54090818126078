<template>
  <div class="container">
    <div class="user-nav">
      <Search
        :termlist="termlist"
        :classlist="classlist"
        ref="search"
        @handleQuery="handleQuery"
      />
    </div>

    <div class="main">
      <el-row type="flex" justify="space-between">
        <el-col :span="6">
          <h3>综素成绩进度</h3>
        </el-col>
        <el-col :span="18" class="btn" style="text-align: right">
          <el-button type="primary" plain>
            <download-excel
              :data="tableData"
              :fields="fields"
              name="综素成绩进度.xls"
            >
              导出
            </download-excel>
          </el-button>
        </el-col>
      </el-row>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column type="index" label="序号" width="50" fixed="left">
        </el-table-column>
        <el-table-column prop="userName" label="学生姓名" width="150" fixed="left"> </el-table-column>
        <el-table-column prop="loginName" label="学籍号" width="180">
        </el-table-column>
        <el-table-column prop="deptName" label="班级" width="120">
        </el-table-column>
        <el-table-column prop="type301" label="思想品德" width="130"> </el-table-column>
        <el-table-column prop="type309" label="学业水平" width="130"> </el-table-column>
        <el-table-column prop="type320" label="身心健康" width="130"> </el-table-column>
        <el-table-column prop="type333" label="艺术素养" width="130"> </el-table-column>
        <el-table-column prop="type341" label="社会实践(初中累计)" width="180">
        </el-table-column>
				<el-table-column prop="isTermDes" label="学期评价状态" width="180">
				</el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="handleDetail(scope.row)" type="text" size="small"
              >查看详情</el-button
            >
						<el-button @click="openTermEva(scope.row)" type="text" size="small"
						  >学期评价</el-button
						>
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
			
			<el-dialog title="学期评价" :visible.sync="termEva.show" width="500px" center :close-on-click-modal="false">
				<el-form :model="termEva">
				  <el-form-item prop="more" label="学期评价">
				    <el-input
				      v-model="termEva.cont"
				      type="textarea"
				      :rows="5"
				      placeholder="请输入学期评价"
				    ></el-input>
				  </el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
				  <el-button @click="closeTermEva">关 闭</el-button>
				  <el-button type="primary" @click="confirmTermEva" :disabled="!termEva.cont">提交评价</el-button>
				</div>
			</el-dialog>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search";
import { getSummary, termEva } from "@/api/teacher/quality/summary";
export default {
  data() {
    return {
      total: 0,
      termlist: [],
      classlist: [],
      queryParams: {
				term: '',
        pageSize: 100,
      },
      tableData: [],
			termEva: {
				show: false,
				cont: '',
				nm: '',
				uid: '',
				loginNm: '',
				cls: ''
			},
      fields: {
        学生姓名: "userName",
        学籍号: "loginName",
        班级: "deptName",
        思想品德: "type301",
        学业水平: "type309",
        身心健康: "type320",
        艺术素养: "type333",
        "社会实践(初中累计)": "type341",
      },
    };
  },
  components: { Search },
  created() {
    this.getFirst();
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.term = this.$store.getters.queryForm.termName;
        this.queryParams.deptId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(this.queryParams.term, this.queryParams.deptId);
        this.getList();
      });
    },
    getList() {
      getSummary(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.deptId = data.deptId;
      this.queryParams.term = data.term;
      this.queryParams.loginNameOrUserName = data.userName;
      this.getList();
    },
    handleDetail(row) {
      const params = {
        userId: row.userId,
        userName: row.userName,
        studentCode: row.loginName,
        termName: this.queryParams.term,
        className: row.deptName,
        classId: this.queryParams.deptId,
      };
      this.$router.push({ name: "summaryDetail", params: params });
      sessionStorage["params"] = JSON.stringify(params);
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
		// 打开学期评价
		openTermEva (item) {
			this.termEva.nm = item.userName
			this.termEva.uid = item.userId
			this.termEva.cls = item.deptName
			this.termEva.loginNm = item.loginName
			this.termEva.cont = item.termDes
			this.termEva.show = true
		},
		// 关闭学期评价
		closeTermEva () {
			this.termEva.show = false
		},
		// 提交学期评价
		confirmTermEva () {
			termEva({
				userId: this.termEva.uid,
				termName: this.queryParams.term,
				termDes: this.termEva.cont
			}).then(res => {
				this.$message.success('学期评价更新成功')
				this.closeTermEva()
				this.getList()
			})
		}
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .user-nav {
    padding: 10px 12px;
  }
}
</style>